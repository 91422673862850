<template>
  <div class="wrapper d-flex align-center">
    <v-menu
      v-if="hasComment"
      :attach="attachEl"
      offset-y
      content-class="background-translucid"
      close-delay=50
      :z-index="displayZIndex"
      min-width="max-content"
      ref="menu"
      transition="slide-y-transition"
      origin="right top"
      :nudge-bottom="displayToTop ? 0 : 4"
      :nudge-top="displayToTop ? 4 : 0"
      :top="displayToTop"
      :left="displayToLeft"
      v-model="menu"
    >
      <template v-slot:activator="{ attrs }">
        <div
          class="d-flex align-end"
          v-bind="attrs"
          @mouseover="openMenu"
          @mouseleave="closeMenu"
        >
          <span class="text-no-wrap">{{commentPreview}}</span>
          <v-icon
            small
            v-if="!commentPreviewIsFull"
          >{{$icon('i.DotsHorizontal')}}</v-icon>
        </div>
      </template>
      <v-card
        class="background-plain ma-0 d-flex flex-column"
        ref="content"
        @mouseover="openMenu"
        @mouseleave="closeMenu"
        @click.native.stop
      >
        <resize-observer @resize="computePosition()">
          <v-card-text class="content-menu background-plain">
            <div class="d-flex align-center flex-row">
              <user-ref
                hide-group
                hide-fullname
                hide-email
                hide-phone
                :id="createBy"
                :close="false"
                :avatarProps="{xsmall: true}"
              />
              <span class="secondary--text ml-2 mr-2">
                {{$t('t.CreateBy')}}
              </span>
              <user-ref
                block-popup
                hide-group
                hide-email
                hide-phone
                :avatar-props="{visible: false}"
                :id="createBy"
                :close="false"
              />
              <calendar-date
                class="ml-2"
                :date="createAt"
                show-time
              />
              <v-spacer />
              <v-btn
                class="ml-4"
                icon
                small
                @click.stop="navigateTo()"
              >
                <icon-tooltip
                  :icon-name="'i.OpenInSidePanel'"
                  :tooltip-text="$t('t.OpenInSidePanel')"
                  small
                />
              </v-btn>
            </div>
            <div
              comment
              class="mt-4"
            >
              {{value}}
            </div>
          </v-card-text>
        </resize-observer>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'
import DisplayableInContainer from '@/pages/search/mixins/displayable-in-container'
import navigation from '@/navigationController'

export default {
  inheritAttrs: false,
  mixins: [DisplayableInContainer, ClientCache],
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    UserRef: () => import('@/components/documents-ref/user-ref'),
    ResizeObserver: () => import('@/components/resize-observer'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      menu: false,
      openMenuHandler: undefined,
      closeMenuHandler: undefined
    }
  },
  computed: {
    topicId () {
      return this.cache?.doc?.topicId
    },
    value () {
      return this.cache?.doc?.text
    },
    createBy () {
      return this.cache?.doc?.createBy
    },
    createAt () {
      return this.cache?.doc?.createAt
    },
    hasComment () {
      return !!this.id
    },
    commentPreview () {
      return this.value?.substring(0, 30)
    },
    commentPreviewIsFull () {
      return this.value?.length <= 30
    },
    cacheType () {
      return ClientCache.CacheType.PostRef
    }
  },
  methods: {
    cleanUpClose () {
      if (this.closeMenuHandler) {
        clearTimeout(this.closeMenuHandler)
        this.closeMenuHandler = undefined
      }
    },
    cleanUpOpen () {
      if (this.openMenuHandler) {
        clearTimeout(this.openMenuHandler)
        this.openMenuHandler = undefined
      }
    },
    openMenu () {
      this.cleanUpClose()
      this.openMenuHandler = setTimeout(() => {
        this.cleanUpOpen()
        this.menu = true
      }, 200)
    },
    navigateTo () {
      navigation.navigateTo(this.topicId, 'topics')
    },
    closeMenu () {
      this.cleanUpOpen()
      this.closeMenuHandler = setTimeout(() => {
        this.cleanUpClose()
        this.menu = false
      }, 50)
    }
  },
  props: {
    id: Number,
    mixed: Boolean,
    dense: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative

.content-menu
  overflow-y true
  max-width 600px
  max-height 600px

.comment
  white-space pre-line
</style>
